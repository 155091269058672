* {
    font-family: 'Poppins', sans-serif;
}

.container {
    max-width: 1240px;
}

.banner-top-content span {
    font-weight: inherit !important;
}

ul,
li {
    list-style: none;
}

a {
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    text-decoration: none !important;
}

.display-inline {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.abroad-heading {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    text-align: center;
}

.abroad-heading h2 {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 36px;
    line-height: 44px;
    color: #225e98;
    font-weight: 700;
    position: relative;
}

.abroad-heading h2 span {
    font-weight: 700 !important;
    color: #eba40a;
}

.abroad-heading.with-border h2 {
    margin-bottom: 25px;
}

.abroad-heading.with-border h2:before {
    position: absolute;
    left: 50%;
    bottom: -14px;
    width: 200px;
    height: 2px;
    margin-left: -100px;
    background-color: #eba40a;
    content: "";
}

.abroad-heading.with-border h2:after {
    position: absolute;
    left: 50%;
    bottom: -20px;
    width: 16px;
    height: 16px;
    margin-left: -8px;
    background-color: #eba40a;
    content: "";
    border: 4px solid #fff;
}

.abroad-heading p {
    display: inline-block;
    width: 100%;
    max-width: 750px;
    vertical-align: top;
    margin: 5px 0 15px 0;
    padding: 0;
    font-size: 18px;
    line-height: 30px;
    color: #000 !important;
    font-weight: 400 !important;
}

.abroad-top-section {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    position: relative;
    background-color: #032343;
}

.abroad-top-section:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 420px;
    content: "";
    background-image: url("/public/images/header-left-image.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: top left;
}

.abroad-top-section .banner-top-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
}

.abroad-top-section .banner-top-image img {
    float: right;
    width: 100%;
    max-width: 500px;
}

.abroad-top-section .banner-top-image:after {
    position: absolute;
    left: 87%;
    top: 10%;
    width: 170px;
    height: 260px;
    content: "";
    background-image: url("/public/images/header-top-icon.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
    z-index: 1;
}

.abroad-top-section .banner-top-content {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 70px 0 80px 30px;
}

.abroad-top-section .banner-top-content h1 {
    font-size: 48px;
    line-height: 64px;
    color: #fff;
    font-weight: 400;
}

.abroad-top-section .banner-top-content h1 span {
    font-weight: 400 !important;
    color: #fee09f;
}

.abroad-top-section .banner-top-content h2 {
    font-size: 24px;
    line-height: 34px;
    color: #f0aa0c;
    font-weight: 400;
    margin: 20px 0 0 0;
}

.abroad-top-section .banner-top-content p {
    font-size: 16px;
    line-height: 30px;
    color: #ffffff !important;
    margin: 20px 0 0 0;
}

.abroad-top-section .banner-top-content .top-btn {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin: 35px 0 0 0;
    padding-left: 50px;
}

.abroad-top-section .banner-top-content .top-btn a {
    display: inline-block;
    width: auto;
    vertical-align: top;
    padding: 15px 60px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500 !important;
    color: #fff !important;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 40px;
    border: 2px solid #4889c9;
    background-image: linear-gradient(to right, #0d3863, #2462a0);
    position: relative;
    overflow: hidden;
}

.abroad-top-section .banner-top-content .top-btn a:after {
    background: #fff;
    content: "";
    height: 100%;
    left: -100%;
    opacity: 0.09;
    position: absolute;
    top: 0;
    transition: all 0.3s ease-in-out;
    width: 100%;
    z-index: 1;
}

.abroad-top-section .banner-top-content .top-btn a:hover:after {
    left: 0;
}

.abroad-section1 {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    position: relative;
    z-index: 3;
    margin-top: -90px;
}

.abroad-section1 .abroad-top-links {
    display: flex;
    width: 100%;
    background-color: #ffd066;
}

.abroad-section1 .abroad-top-links .links-item {
    width: 14.285%;
    text-align: center;
    border-right: 1px solid #000;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.abroad-section1 .abroad-top-links .links-item:hover {
    background-color: #eba40a;
}

.abroad-section1 .abroad-top-links .links-item.border-none {
    border-right: none;
}

.abroad-section1 .abroad-top-links .links-item .link-icon {
    display: inline-block;
    width: 100%;
    padding: 10px;
    text-align: center;
}

.abroad-section1 .abroad-top-links .links-item .link-icon img {
    display: inline-block;
    width: auto;
    max-width: 80px;
}

.abroad-section1 .abroad-top-links .links-item p {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-size: 15px;
    line-height: 20px;
    color: #000 !important;
    font-weight: 600 !important;
}

.abroad-section1 .abroad-top-links .links-item a.full-link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.abroad-section2 {
    padding: 60px 0 20px 0;
    position: relative;
}

.abroad-section2:before {
    position: absolute;
    left: 0;
    top: -30px;
    width: 440px;
    height: 525px;
    content: "";
    background-image: url("/public/images/body-bg1.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: left top;
}

.abroad-section2:after {
    position: absolute;
    right: 0;
    top: -48px;
    width: 312px;
    height: 676px;
    content: "";
    background-image: url("/public/images/body-bg2.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: right top;
}

.abroad-section2 .container {
    position: relative;
    z-index: 2;
}

.abroad-section2 .countries-section {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 30px;
}

.abroad-section2 .countries-section .countries-block {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 5px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
}

.abroad-section2 .countries-section .countries-block .country-img {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.abroad-section2 .countries-section .countries-block .country-img img {
    display: block;
    width: 100%;
    border-radius: 5px 5px 0 0;
}

.abroad-section2 .countries-section .countries-block .country-icon {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    text-align: center;
    margin-top: -34px;
    position: relative;
    z-index: 1;
}

.abroad-section2 .countries-section .countries-block .country-icon span {
    display: inline-block;
    vertical-align: top;
    width: 68px;
    height: 68px;
    background-color: #fff;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.1);
}

.abroad-section2 .countries-section .countries-block .country-icon span img {
    display: block;
    width: 100%;
    border-radius: 50%;
}

.abroad-section2 .countries-section .countries-block h2 {
    display: inline-block;
    width: 100%;
    padding: 0 5px;
    margin: 15px 0 15px 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #000;
    text-align: center;
}

.abroad-section2 .countries-section .countries-block .more-btn {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.abroad-section2 .countries-section .countries-block .more-btn a {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    background-color: #ffd066;
    text-align: center;
    color: #000 !important;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600 !important;
    padding: 10px 5px;
    border-radius: 0 0 5px 5px;
}

.abroad-section2 .countries-section .countries-block .more-btn a:after {
    display: inline-block;
    vertical-align: top;
    width: 30px;
    height: 20px;
    content: "";
    background-image: url("/public/images/arrow-icon.png");
    background-repeat: no-repeat;
    background-size: 16px auto;
    background-position: left center;
    margin: 0 0 0 8px;
    transition: all 0.3s ease-in-out;
}

.abroad-section2 .countries-section .countries-block .more-btn a:hover:after {
    background-position: 5px center;
}

.abroad-section3 {
    padding-top: 70px;
}

.preparation-section {
    margin-top: 10px;
    margin-bottom: 60px;
    padding-top: 25px;
    background-image: url("/public/images/test-preparations-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.preparation-section .preparation-box {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    position: relative;
    transition: all 0.5s ease-in-out;
    border: 1px solid #d4d9e6;
    border-radius: 5px;
    overflow: hidden;
}

.preparation-section .preparation-box:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #000;
    opacity: 0.5;
    content: "";
    transition: all 0.5s ease-in-out;
}

.preparation-section .preparation-box:hover:after {
    opacity: 0;
}

.preparation-section .preparation-box:before {
    position: absolute;
    right: 20px;
    top: 10%;
    width: 34px;
    height: 34px;
    z-index: 2;
    background-color: #eba40a;
    border-radius: 50%;
    content: "\f061";
    font-family: fontawesome;
    font-size: 14px;
    line-height: 34px;
    color: #fff;
    text-align: center;
    transition: all 0.5s ease-in-out;
}

.preparation-section .preparation-box .preparation-img {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.preparation-section .preparation-box .preparation-img img {
    display: block;
    width: 100%;
}

.preparation-section .preparation-box h2 {
    position: absolute;
    left: 0;
    bottom: 15px;
    padding: 0 30px 10px;
    font-size: 28px;
    line-height: 38px;
    color: #fff;
    font-weight: 700;
    z-index: 2;
    transition: all 0.5s ease-in-out;
}

.preparation-section .preparation-box:hover h2,
.preparation-section .preparation-box:hover:before {
    opacity: 0;
    visibility: hidden;
}

.preparation-section .preparation-box .preparation-cnt {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 3;
    background-color: rgba(254, 254, 254, 0.85);
    padding: 20px 15px 50px 15px;
    border-radius: 0 0 2px 2px;
    transition: all 0.5s ease-in-out;
    transform: translateY(100%);
}

.preparation-section .preparation-box:hover .preparation-cnt {
    transform: translateY(0);
}

.preparation-section .preparation-box .preparation-cnt:after {
    position: absolute;
    right: 20px;
    top: 10%;
    width: 34px;
    height: 34px;
    z-index: 2;
    background-color: #000;
    border-radius: 50%;
    content: "\f061";
    font-family: fontawesome;
    font-size: 14px;
    line-height: 34px;
    color: #fff;
    text-align: center;
}

.preparation-section .preparation-box .preparation-cnt h3 {
    display: inline-block;
    width: 100%;
    padding: 0 45px 0 0;
    vertical-align: top;
    margin: 0 0 20px 0;
}

.preparation-section .preparation-box .preparation-cnt h3 a {
    font-size: 24px;
    line-height: 30px;
    color: #000 !important;
    font-weight: 700 !important;
}

.preparation-section .preparation-box .preparation-cnt h3 a:hover {
    color: #eba40a !important;
}

.preparation-section .preparation-box .preparation-cnt p {
    display: inline-block;
    width: 100%;
    padding: 0;
    vertical-align: top;
    font-size: 16px;
    line-height: 24px;
    color: #000 !important;
    font-weight: 400 !important;
    margin: 0;
}

.preparation-block {
    position: relative;
}

.preparation-block .owl-dots {
    position: absolute;
    left: 0;
    bottom: -60px;
    height: 20px;
    width: 100%;
    margin: 0 !important;
    background-image: url("/public/images/owl-dot-bg.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    text-align: center;
}

.preparation-block .owl-dots .owl-dot {
    margin: 0 4px;
    display: inline-block;
    outline: none;
}

.preparation-block .owl-dots .owl-dot span {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    background: #D6D6D6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px;
}

.preparation-block .owl-dots .owl-dot.active span,
.preparation-block .owl-dots .owl-dot.hover span {
    background: #F8A30C;
    outline: none;
}

.abroad-section4 {
    padding: 80px 0;
    position: relative;
}

.abroad-section4:after {
    position: absolute;
    left: 0;
    top: 100px;
    width: 349px;
    height: 474px;
    content: "";
    background-image: url("/public/images/body-bg3.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: left top;
}

.abroad-section4:before {
    position: absolute;
    right: 0;
    top: 10px;
    width: 800px;
    height: 811px;
    content: "";
    background-image: url("/public/images/body-bg4.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: left top;
}

.abroad-section4 .container {
    position: relative;
    z-index: 1;
}

.abroad-section4 .visa-block {
    margin-top: 50px;
}

.abroad-section4 .visa-block .visa-box {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    background-color: #fff;
    padding: 5px;
    /*box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);*/
    border: 1px solid #d4d9e6;
    border-radius: 5px;
    position: relative;
}

.abroad-section4 .visa-block .visa-box:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(254, 254, 254, 0.8);
    content: "";
    border-radius: 5px;
}

.abroad-section4 .visa-block .visa-box .image {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.abroad-section4 .visa-block .visa-box .image img {
    display: block;
    width: 100%;
}

.abroad-section4 .visa-block .visa-box .visa-cont {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding: 15px;
}

.abroad-section4 .visa-block .visa-box .visa-cont .icon {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 30px;
    text-align: center;
}

.abroad-section4 .visa-block .visa-box .visa-cont .icon img {
    display: inline-block;
    width: 84px;
    height: 84px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
}

.abroad-section4 .visa-block .visa-box .visa-cont h2 {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin: 12px 0 0 0;
    padding: 0;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    color: #225e98;
    text-align: center;
}

.abroad-section4 .visa-block .visa-box .visa-cont .more-btn {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 30px;
    text-align: center;
}

.abroad-section4 .visa-block .visa-box .visa-cont .more-btn a {
    display: inline-block;
    width: auto;
    background-color: #eba40a;
    font-size: 16px;
    line-height: 24px;
    padding: 6px 20px 6px;
    border-radius: 10px;
    font-weight: 400 !important;
    color: #fff !important;
}

.abroad-section4 .visa-block .visa-box .visa-cont .more-btn a:hover {
    background-color: #0d3964;
}

.abroad-section4 .visa-block .owl-stage-outer {
    padding: 15px 0;
}

.visa-block.owl-carousel .owl-nav {
    text-align: center;
    margin-top: 10px;
}

.visa-block.owl-carousel .owl-nav button.owl-next,
.visa-block.owl-carousel .owl-nav button.owl-prev {
    width: 36px;
    height: 36px;
    background-color: #215d98;
    border-radius: 50%;
    opacity: 1;
    position: relative;
    border: none;
    outline: none;
    margin: 0 5px;
}

.visa-block.owl-carousel .owl-nav button.owl-next span,
.visa-block.owl-carousel .owl-nav button.owl-prev span {
    display: none;
}

.visa-block.owl-carousel .owl-nav button.owl-next:after,
.visa-block.owl-carousel .owl-nav button.owl-prev:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    content: "\f061";
    font-family: 'FontAwesome';
    font-size: 14px;
    line-height: 36px;
    color: #fff;
    text-align: center;
    font-weight: 400;
}

.visa-block.owl-carousel .owl-nav button.owl-prev:after {
    content: "\f060";
}

.abroad-section5 .container {
    position: relative;
    padding-top: 80px;
    padding-bottom: 40px;
}

.abroad-section5 .container:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("/public/images/body-bg5.png");
    background-repeat: repeat-y;
    background-size: 100% auto;
    background-position: top center;
    z-index: -1;
}

.abroad-section5 .documentations-block {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 60px;
}

.abroad-section5 .documentations-block .documentations-box {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    text-align: center;
}

.abroad-section5 .documentations-block .documentations-box .icon {
    display: inline-block;
    width: 150px;
    height: 150px;
    vertical-align: top;
    border-radius: 50%;
    padding: 45px;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    position: relative;
}

.abroad-section5 .documentations-block .documentations-box .icon img {
    display: inline-block;
    width: auto;
    vertical-align: top;
}

.abroad-section5 .documentations-block .documentations-box .icon .number {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -25px;
    margin: auto;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    z-index: 1;
    background-color: #eba40a;
    font-size: 24px;
    line-height: 44px;
    text-align: center;
    color: #fff;
    font-weight: 300 !important;
}

.abroad-section5 .documentations-block .documentations-box .cnt {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 50px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    border-radius: 45px;
    padding: 20px 15px;
    text-align: center;
    position: relative;
    transition: all 0.5s ease-in-out;
}

.abroad-section5 .documentations-block .documentations-box .cnt:hover {
    background-color: #030507;
}

.abroad-section5 .documentations-block .documentations-box .cnt h3 {
    font-size: 18px;
    line-height: 24px;
    color: #4376a7;
    font-weight: 600;
    margin: 0;
    padding: 0;
    transition: all 0.5s ease-in-out;
}

.abroad-section5 .documentations-block .documentations-box .cnt:hover h3 {
    color: #eba40a;
}

.abroad-section5 .documentations-block .documentations-box .cnt p {
    font-size: 16px;
    line-height: 24px;
    color: #000 !important;
    font-weight: 400 !important;
    padding: 12px 0 0 0;
    margin: 0;
    transition: all 0.5s ease-in-out;
}

.abroad-section5 .documentations-block .documentations-box .cnt:hover p {
    color: #fff !important;
}

.abroad-section5 .documentations-block .documentations-box .cnt .full-link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.abroad-section6 {
    margin-top: 0;
    padding-top: 70px;
    background-image: url("/public/images/video-gallery-bg.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
    position: relative;
    z-index: 3;
}

.abroad-section6 .video-block {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 60px;
}

.abroad-section6 .video-block .video-box {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    position: relative;
}

.abroad-section6 .video-block .video-box .full-link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.abroad-section6 .video-block .video-box .video-image {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    position: relative;
}

.abroad-section6 .video-block .video-box .video-image img {
    display: block;
    width: 100%;
}

.abroad-section6 .video-block .video-box .video-image:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #000;
    opacity: 0.4;
    content: "";
    transition: all 0.5s ease-in-out;
}

.abroad-section6 .video-block .video-box:hover .video-image:after {
    opacity: 0;
}

.abroad-section6 .video-block .video-box .video-image:before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    z-index: 2;
    content: "";
    background-image: url("/public/images/video-play-icon.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
}

.abroad-section6 .video-block .video-box .video-title {
    display: flex;
    width: 100%;
    height: auto;
    min-height: 72px;
    background-color: #ffd066;
    color: #000;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    padding: 10px 20px;
    margin-top: 1px;
    align-items: center;
}

.video-block.owl-carousel .owl-nav {
    position: absolute;
    right: 0;
    top: -50px;
    width: auto;
    z-index: 1;
}

.video-block.owl-carousel .owl-nav button.owl-prev,
.video-block.owl-carousel .owl-nav button.owl-next {
    width: 25px;
    height: 25px;
    border-radius: 3px;
    background-color: #012541;
    transition: all 0.5s ease-in-out;
    border: none !important;
    outline: none !important;
    margin: 0;
}

.video-block.owl-carousel .owl-nav button.owl-prev {
    margin-right: 10px;
}

.video-block.owl-carousel .owl-nav button.owl-prev:hover,
.video-block.owl-carousel .owl-nav button.owl-next:hover {
    background-color: #eba40a;
}

.video-block.owl-carousel .owl-nav button.owl-prev span,
.video-block.owl-carousel .owl-nav button.owl-next span {
    color: #fff;
    font-size: 21px;
    font-weight: 300 !important;
    line-height: 24px;
}

.abroad-section7 {
    padding: 80px 0 20px 0;
    position: relative;
}

.abroad-section7:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 312px;
    height: 676px;
    content: "";
    background-image: url("/public/images/body-bg2.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: left top;
}

.abroad-section7:after {
    position: absolute;
    right: 0;
    top: 20px;
    width: 349px;
    height: 474px;
    content: "";
    background-image: url("/public/images/body-bg3.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: right top;
}

.abroad-faq-section {
    margin-top: 60px;
}

.abroad-faq-section .abroad-faq-block {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding-top: 105px;
    position: relative;
}

.abroad-faq-section .abroad-faq-block:before {
    position: absolute;
    left: 0;
    top: -100px;
    width: 220px;
    height: 260px;
    content: "";
    background-image: url("/public/images/faq-image01.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
}

.abroad-faq-section .abroad-faq-block .accordion {
    position: relative;
    z-index: 2;
}

.abroad-faq-section .abroad-faq-block .accordion .accordion-item {
    background-color: #ecf0f4;
    border: 1px solid #d4d9e6;
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 20px 15px 20px 15px;
    position: relative;
    cursor: pointer;
}

.abroad-faq-section .abroad-faq-block .accordion .accordion-item:before {
    display: none;
}

.abroad-faq-section .abroad-faq-block .accordion .accordion-item.show {
    background-color: #fff;
}

.abroad-faq-section .abroad-faq-block .accordion .accordion-item .accordion-header {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: #1d2f41;
    font-weight: 600;
    padding: 0 45px 0 0;
    position: relative;
}

.abroad-faq-section .abroad-faq-block .accordion .accordion-item .accordion-header:after {
    position: absolute;
    left: auto;
    right: 0;
    top: -8px;
    border-radius: 50%;
    margin: 0;
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    content: "\f054";
    color: #1d2f41;
    line-height: 40px;
    font-size: 14px;
    font-family: 'FontAwesome';
    text-align: center;
}

.abroad-faq-section .abroad-faq-block .accordion .accordion-item.show .accordion-header:after {
    background-color: #eba40a;
    color: #fff;
    content: "\f078";
}

.abroad-faq-section .abroad-faq-block .accordion .accordion-item .accordion-body {
    padding: 20px 45px 0 0;
    display: none;
}

.abroad-faq-section .abroad-faq-block .accordion .accordion-item .accordion-body p {
    padding: 0;
    font-size: 14px;
    line-height: 24px;
    color: #1d2f41 !important;
    font-weight: 400 !important;
}

.abroad-faq-section .faq-right-block {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.abroad-faq-section .faq-right-block .right-top-block {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    background-color: #eba40a;
    padding: 50px 180px 50px 20px;
    font-size: 28px;
    line-height: 36px;
    color: #fff;
    font-weight: 600;
    background-image: url("/public/images/faq-image02.png");
    background-repeat: no-repeat;
    background-size: 200px auto;
    background-position: right center;
}

.abroad-faq-section .faq-right-block .right-image {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    position: relative;
    margin-top: 5px;
}

.abroad-faq-section .faq-right-block .right-image img {
    display: block;
    width: 100%;
}

.abroad-faq-section .faq-right-block .right-image p {
    position: absolute;
    width: auto;
    max-width: 90%;
    left: 0;
    top: 50%;
    z-index: 1;
    background-color: rgba(254, 254, 254, 0.89);
    font-size: 18px;
    line-height: 24px;
    padding: 12px 15px;
    color: #0a508b !important;
    font-weight: 400 !important;
    margin: -24px 0 0 0;
}

.abroad-section8 {
    margin-top: 120px;
    position: relative;
    min-height: 700px;
    padding: 70px 0 80px 0;
    background-image: url("/public/images/body-bg6.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.abroad-blog-section {
    margin-top: 50px;
}

.abroad-blog-section .abroad-blogs {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    position: relative;
}

.abroad-blog-section .abroad-blogs .blog-image {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.abroad-blog-section .abroad-blogs .blog-image img {
    display: block;
    width: 100%;
}

.abroad-blog-section .abroad-blogs .blog-cnt {
    display: inline-block;
    width: calc(100% - 14px);
    vertical-align: top;
    margin: -80px 0 0 7px;
    background-color: #fff;
    padding: 50px 20px 40px 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1;
}

.abroad-blog-section .abroad-blogs .blog-cnt .blog-date {
    position: absolute;
    right: 32px;
    top: -48px;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    z-index: 1;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    text-align: center;
    background-color: #eba40a;
    padding: 12px 2px;
    font-weight: 400;
}

.abroad-blog-section .abroad-blogs .blog-cnt .blog-date span {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-size: 16px;
    font-weight: 400 !important;
}

.abroad-blog-section .abroad-blogs .blog-cnt h3 {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-size: 18px;
    line-height: 24px;
    color: #225e98;
    font-weight: 700;
    padding: 0;
    margin: 0;
}

.abroad-blog-section .abroad-blogs .blog-cnt p {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-size: 14px;
    line-height: 24px;
    color: #000 !important;
    font-weight: 400 !important;
    padding: 0;
    margin: 30px 0 0 0;
}

.abroad-blog-section .abroad-blogs .blog-cnt .blog-btn {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 30px;
    text-align: center;
}

.abroad-blog-section .abroad-blogs .blog-cnt .blog-btn a {
    display: inline-block;
    width: auto;
    vertical-align: top;
    background-color: #fff;
    text-align: center;
    color: #000 !important;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600 !important;
    padding: 10px 16px 10px 20px;
    border-radius: 10px;
    border: 1px solid #d4d9e6;
}

.abroad-blog-section .abroad-blogs .blog-cnt .blog-btn a:hover {
    background-color: #225e98;
    color: #fff !important;
}

.abroad-blog-section .abroad-blogs .blog-cnt .blog-btn a:after {
    display: inline-block;
    vertical-align: top;
    width: 24px;
    height: 20px;
    content: "";
    background-image: url("/public/images/black-arrow-icon.png");
    background-repeat: no-repeat;
    background-size: 16px auto;
    background-position: left center;
    margin: 0 0 0 8px;
    transition: all 0.3s ease-in-out;
}

.abroad-blog-section .abroad-blogs .blog-cnt .blog-btn a:hover:after {
    background-image: url("/public/images/white-arrow-icon.png");
}

.subscribe.abroad-subscribe {
    background-color: #0c3762;
    border-top: 2px solid #eba309;
}


/*header css start here*/

.header-top {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    /* background: linear-gradient(45deg, #0f2031, #021222); */
    background: #061522;
}

.header-ul {
    list-style: none;
    vertical-align: middle;
    text-align: right;
    padding: 0;
    margin: 0 15px;
    margin-top: 30px;
}

.header-ul li {
    display: inline;
}

.header-ul li+li {
    padding-left: 30px;
}

.header-ul li a {
    font-size: 16px;
    color: #747474 !important;
}

.header-ul li a .fa {
    color: #1a3581;
    padding-right: 8px;
}

.header-ul li a .fa-phone {
    font-size: 20px;
}

.header-ul li a .fa-whatsapp {
    font-size: 25px;
    color: #2bb43e;
}

.header-top .header-ul {
    margin: 7px 0;
    float: right;
}

.header-top .header-ul li {
    float: left;
    position: relative;
    padding-left: 10px;
    margin-left: 10px;
    height: 20px;
    margin-top: 4px;
}

.header-top .header-ul li:marker {
    display: none;
}

.header-top .header-ul li:before {
    position: absolute;
    left: 0;
    top: 1px;
    width: 1px;
    height: 16px;
    background: #ccc;
    content: "";
}

.header-top .header-ul li:first-child:before {
    display: none;
}

.header-top .header-ul li:first-child {
    margin-left: 0;
    padding-left: 0;
}

.header-top .header-ul li a {
    color: #fff !important;
    font-size: 10px;
    line-height: 16px !important;
    font-weight: 600 !important;
    display: inline-block;
    width: auto;
    vertical-align: top;
    padding-top: 1px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.header-top .header-ul li a.enroll-now {
    padding: 1px 8px 2px;
    background-color: #F00;
}

.header-top .header-ul li a.login {
    padding: 1px 8px 2px;
    background-color: #69AA09;
}

.header-top .header-ul li a.fee-deposition {
    padding: 1px 8px 2px;
    background-color: #F8A30C;
}

.logo {
    width: 190px !important;
    margin: 15px 0 !important;
}

.d-lg-none {
    display: none !important;
}

.bg-theme-menu {
    /* background: linear-gradient(45deg, #F8A30C, #ffd385); */
    background: #eba309 !important;
}

header .dropdown:hover>.dropdown-menu,
header .dropend:hover>.dropdown-menu {
    display: block;
    margin-top: 0.125em;
    margin-left: 0.125em;
}

header .navbar {
    background: linear-gradient(45deg, #F8A30C, #ffd385);
    background: #eba309 !important;
    padding: 0;
}

header .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 13px;
    line-height: 18px;
    color: #000;
    font-weight: 500;
}

header .navbar-expand-lg .dropdown-toggle::after {
    margin: 7px 0 0 2px;
    vertical-align: top;
}

header .navbar-expand-lg .navbar-nav .dropdown-menu {
    top: 31px;
    border-radius: 0;
    padding: 0;
}

header .navbar-expand-lg .navbar-nav .dropdown-menu li {
    padding: 0;
    border-bottom: 1px solid #ccc;
}

header .navbar-expand-lg .navbar-nav .dropdown-menu li:last-child {
    border-bottom: none;
}

header .navbar-expand-lg .navbar-nav .dropdown-menu li a {
    padding: 10px 15px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #626262;
}

header .navbar-expand-lg .navbar-nav .dropdown-menu li a:hover {
    background: #B07B0A;
    color: #fff;
}

header .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-menu {
    left: 100%;
    top: 0;
    margin: 0;
}

header .navbar-expand-lg .dropdown-menu .dropdown-toggle::after {
    right: 8px;
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    position: absolute;

}

.desktop-hide-section {
    display: none;
}

header .navbar-toggler {
    display: none;
}

/*header css end here*/
.inner-top-section {
    position: relative;
    background-color: #032343;
    padding: 80px 0 0 0;
}

.inner-top-section:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 292px;
    height: 303px;
    content: "";
    background-image: url("/public/images/body-bg8.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: left center;
}

.inner-top-section:after {
    position: absolute;
    right: 0;
    bottom: 50px;
    width: 129px;
    height: 126px;
    content: "";
    background-image: url("/public/images/body-bg9.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
}

.inner-top-section .banner-top-content {
    display: inline-block;
    width: 100%;
    max-width: 480px;
    text-align: center;
    padding-top: 40px;
}

.inner-top-section .banner-top-content h1 {
    font-size: 48px;
    line-height: 60px;
    color: #fff;
    font-weight: 700;
}

.inner-top-section .banner-top-content h1 span {
    color: #eba40a;
}

.inner-top-section .banner-top-image {
    float: right;
}

.inner-content-section .content-section1 {
    padding: 40px 0 0 0;
    position: relative;
}

.inner-content-section .content-section1:after {
    position: absolute;
    left: 0;
    top: 50px;
    width: 745px;
    height: 600px;
    content: "";
    background-image: url("/public/images/body-bg10.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    opacity: 0.7;
}

.inner-content-section .content-section1 .container {
    position: relative;
    z-index: 1;
}

.inner-content-section h2 {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-size: 28px;
    line-height: 38px;
    color: #225e98;
    font-weight: 700;
    margin: 0 0 10px 0;
}

.inner-content-section h2 span {
    color: #eba40a;
}

.inner-content-section h3 {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-size: 18px;
    line-height: 28px;
    color: #225e98;
    font-weight: 700;
    margin: 0 0 10px 0;
}

.inner-content-section h3 span {
    color: #000;
}

.inner-content-section p {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    font-weight: 400;
    padding: 0 0 15px 0;
    margin: 0;
}

.inner-content-section p span {
    color: #225e98;
}

.inner-content-section ul,
.inner-content-section ol {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin: 0 0 20px 0;
    padding: 0 0 0 20px;
}

.inner-content-section ol {
    counter-reset: item;
}

.inner-content-section ul li,
.inner-content-section ol li {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 0 0 0 30px;
    margin: 0 0 10px 0;
    font-size: 16px;
    line-height: 24px;
    position: relative;
}

.inner-content-section ul li a,
.inner-content-section ol li a {
    color: #225e98;
}

.inner-content-section ul li a:hover,
.inner-content-section ol li a:hover {
    color: #eba40a;
}

.inner-content-section ul li:last-child,
.inner-content-section ol li:last-child {
    margin-bottom: 0;
}

.inner-content-section ul li:before {
    position: absolute;
    left: 0;
    top: 6px;
    width: 12px;
    height: 12px;
    background-image: url("/public/images/icon02.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    content: "";
}

.inner-content-section ol li:before {
    position: absolute;
    left: 0;
    top: 0px;
    counter-increment: item;
    content: counter(item) ". ";
}

.inner-content-section .content-section1 .content-block.bg-color {
    padding: 15px;
    background-color: #fffcf5;
    margin-top: 10px;
}

.content-section1 .content-block .accordion {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.content-section1 .content-block .accordion .accordion-item {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    background-color: #ffe19f;
    border-radius: 10px;
    padding-bottom: 0;
    margin-bottom: 15px;
}

.content-section1 .content-block .accordion .accordion-item .accordion-body {
    display: none;
    padding: 0 15px;
}

.content-section1 .content-block .accordion .accordion-item .accordion-body p {
    font-size: 14px;
    line-height: 24px;
}

.content-section1 .content-block .accordion .accordion-item .accordion-header {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    background-color: transparent;
    border-radius: 10px;
    padding: 10px 40px 10px 15px;
    font-size: 14px;
    line-height: 24px;
    color: #000;
    cursor: pointer;
    position: relative;
    margin: 0;
}

.content-section1 .content-block .accordion .accordion-item .accordion-header:after {
    position: absolute;
    right: 10px;
    top: 11px;
    width: 20px;
    height: 20px;
    font-family: fontawesome;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    content: "\f0d7";
    text-align: center;
}

.content-section1 .content-block .accordion .accordion-item.show .accordion-header:after {
    content: "\f0d8";
}

.inner-content-section aside {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

aside .form-outer {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 25px 20px;
    background-color: #032343;
    border-radius: 30px;
}

.inner-content-section .form-outer h2 {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-size: 28px;
    line-height: 34px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    margin: 0 0 40px 0;
}

.inner-content-section .form-outer h2 span {
    color: #fff;
    display: inline-block;
    vertical-align: top;
    padding-bottom: 5px;
    position: relative;
}

.inner-content-section .form-outer h2 span:after {
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: #468aff;
    content: "";
}

.enquiry-form {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.enquiry-form .row {
    margin-left: -7px;
    margin-right: -7px;
}

.enquiry-form .row .col-md-6 {
    padding-left: 7px;
    padding-right: 7px;
}

.enquiry-form .form-group {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-bottom: 20px;
}

.enquiry-form .form-group .form-control {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    background-color: #072d54;
    outline: none;
    border: 1px solid #468aff;
    height: 48px !important;
    padding: 0 15px;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    border-radius: 10px;
    box-shadow: none;
}

.enquiry-form .form-group .form-control::placeholder {
    color: #fff;
    opacity: 1;
}

.enquiry-form .form-group .form-control::-ms-input-placeholder {
    color: #fff;
    opacity: 1;
}

.enquiry-form .form-group .form-control:focus::placeholder {
    opacity: 0;
}

.enquiry-form .form-group .form-control:focus::-ms-input-placeholder {
    opacity: 0;
}

.enquiry-form .form-group select.form-control {
    background-image: url("/public/images/dropdown-arrow.png");
    background-size: 12px auto;
    background-position: calc(100% - 10px) center;
    background-repeat: no-repeat;
    cursor: pointer;
    padding-right: 26px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.enquiry-form .form-group textarea.form-control {
    height: 110px !important;
    resize: none;
    padding: 10px 15px;
}

.enquiry-form .form-group .custom-control-label {
    color: #fff;
}

.enquiry-form .form-group .custom-control-label:before,
.enquiry-form .form-group .custom-control-label:after {
    box-shadow: none !important;
}

.enquiry-form .form-group.custom-control {
    margin: 10px 0 30px 0;
}

.enquiry-form .form-group.btn-outer {
    text-align: center;
    margin-top: 20px;
}

.enquiry-form .form-group.btn-outer .submit-btn {
    display: inline-block;
    width: auto;
    vertical-align: top;
    padding: 15px 60px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500 !important;
    color: #fff !important;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 40px;
    border: 2px solid #4889c9;
    background-image: linear-gradient(to right, #0d3863, #2462a0);
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.enquiry-form .form-group.btn-outer .submit-btn:after {
    background: #fff;
    content: "";
    height: 100%;
    left: -100%;
    opacity: 0.09;
    position: absolute;
    top: 0;
    transition: all 0.3s ease-in-out;
    width: 100%;
    z-index: 1;
}

.enquiry-form .form-group.btn-outer .submit-btn:hover:after {
    left: 0;
}

.inner-content-section aside .category-list-outer {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 30px;
    background-color: #fffcf5;
    padding: 15px;
    position: relative;
}

.inner-content-section aside .category-list-outer:before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 242px;
    height: 191px;
    content: "";
    background-image: url("/public/images/body-bg11.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: right bottom;
}

.inner-content-section aside .category-list-outer ul {
    padding-left: 0;
    margin-bottom: 0;
}

.inner-content-section aside .category-list-outer ul li:before {
    position: absolute;
    left: 0;
    top: 5px;
    width: 16px;
    height: 16px;
    background-image: url("/public/images/icon03.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    content: "";
}

aside .blog-list-outer {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 30px;
}

.blog-list-outer .blogs {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin: 10px 0 30px;
}

.blog-list-outer .blogs .blog-img {
    float: left;
    width: 110px;
    height: auto;
}

.blog-list-outer .blogs .blog-img img {
    display: block;
    width: 100%;
    border-radius: 10px;
}

.blog-list-outer .blogs .blog-cnt {
    float: left;
    width: calc(100% - 110px);
    padding-left: 15px;
}

.blog-list-outer .blogs .blog-cnt h4 {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-size: 16px;
    line-height: 28px;
    color: #000;
    font-weight: 500;
    padding: 0;
    margin: 0 0 15px 0;
}

.blog-list-outer .blogs .blog-cnt .blog-date {
    float: left;
    width: 50%;
    font-size: 14px;
    line-height: 20px;
    color: #a5a9ac;
    font-weight: 500;
}

.blog-list-outer .blogs .blog-cnt .blog-date.right {
    float: right;
    width: 45%;
}

.blog-list-outer .blogs .blog-cnt .blog-date i {
    display: inline-block;
    width: auto;
    font-size: 18px;
    color: #a5a9ac;
    margin: 0 5px 0 0;
}

.inner-content-section .add-section {
    margin: 40px 0 0 0;
}

.inner-content-section .add-section img {
    display: block;
    width: 100%;
}

.content-section2 {
    margin-top: 40px;
}

.inner-faq-section {
    display: flex;
    width: 100%;
    margin-top: 70px;
}

.inner-faq-section .left-block {
    width: 50%;
}

.inner-faq-section .left-block img {
    display: block;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
}

.inner-faq-section .right-block {
    width: 50%;
    background-color: #fdf0d3;
    padding: 40px 30px;
    position: relative;
}

.inner-faq-section .right-block:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("/public/images/body-bg12.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right top;
    content: "";
    z-index: 1;
}

.inner-faq-section .right-block .inner-faq {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    position: relative;
    z-index: 2;
}

.inner-faq .accordion {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 10px 0 30px 0;
    counter-reset: item;
}

.inner-faq .accordion .accordion-item {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    background-color: #ffe19f;
    border-radius: 10px;
    margin-bottom: 30px;
}

.inner-faq .accordion .accordion-item .accordion-header {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 15px 15px;
    padding-left: 35px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    font-weight: 500;
    cursor: pointer;
    position: relative;
}

.inner-faq .accordion .accordion-item .accordion-header:after {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 15px;
    left: 15px;
    font-family: fontawesome;
    font-size: 20px;
    line-height: 24px;
    color: #000;
    content: "\f0d7";
    text-align: left;
}

.inner-faq .accordion .accordion-item.show .accordion-header:after {
    content: "\f0d8";
}

.inner-faq .accordion .accordion-item .accordion-header:before {
    display: inline-block;
    width: auto;
    vertical-align: top;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    content: "Q " attr(data-question-number);
    /* counter-increment: item;
    content: "Q " counter(item); */
}

.inner-faq .accordion .accordion-item .accordion-body {
    display: none;
    width: 100%;
    padding: 15px;
    border-top: 1px solid #fac757;
    font-size: 16px;
    line-height: 24px;
    color: #000;
}

.related-post-section {
    margin-top: 60px;
}

.related-post-section .post-block {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin: 50px 0;
}

.related-post-section .post-block .post-item {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    height: 100%;
    background-color: #fdf0d3;
    border-radius: 5px 5px 0 0;
    position: relative;
}

.related-post-section .post-block .post-item .post-img {
    display: inline-block;
    width: 100%;
    height: auto;
    vertical-align: top;
}

.related-post-section .post-block .post-item .post-img img {
    display: block;
    height: 280px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
}

.related-post-section .post-block .post-item .post-cnt {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 20px 15px 50px;
    text-align: center;
}

.related-post-section .post-block .post-item .post-cnt h4 {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-size: 18px;
    line-height: 28px;
    color: #000;
    font-weight: 600;
    margin: 0 0 10px 0;
    padding: 0;
}

.related-post-section .post-block .post-item .post-cnt p {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    font-size: 14px;
    line-height: 24px;
    color: #000;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.related-post-section .post-block .post-item .post-cnt .view-more-btn {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -20px;
    z-index: 1;
    text-align: center;
}

.related-post-section .post-block .post-item .post-cnt .view-more-btn a {
    display: inline-block;
    width: auto;
    vertical-align: top;
    background-color: #eba40a;
    border-radius: 30px;
    padding: 12px 40px;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    font-weight: 400;
}

.related-post-section .post-block .post-item .post-cnt .view-more-btn a:hover {
    background-color: #225e98;
}

.related-post-section .post-block .owl-stage-outer {
    padding-bottom: 30px;
}

.related-post-section .post-block .owl-stage-outer .owl-stage {
    display: flex;
}

.related-post-section .post-block .owl-dots {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    text-align: center;
    margin-top: 10px;
}

.related-post-section .post-block .owl-dots .owl-dot {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #f5dda7;
    border-radius: 50%;
    margin: 0 4px;
    border: 4px solid transparent;
    outline: none;
}

.related-post-section .post-block .owl-dots .owl-dot.active {
    background-color: #e99e07;
    border-color: #f5dda7;
}

/*footer css start here*/
.footer-section {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 50px;
}

.footer-section .subscribe {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    position: relative;
    z-index: 1;
    background-color: #0c3762;
    border-top: 2px solid #eba309;
    padding-top: 40px;
}

.footer-section .subscribe .subscribe-box {
    display: inline-block;
    width: 100%;
    background: #eba309;
    margin-bottom: -80px;
    border-radius: 25px;
    padding: 40px;
}

.footer-section .subscribe .subscribe-box span {
    color: #fff;
}

.footer-section .subscribe .subscribe-box h4 {
    font-size: 26px;
    line-height: 34px;
    color: #fff;
}

.footer-section .subscribe .subscribe-box .subscribe-form {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.footer-section .subscribe .subscribe-box .subscribe-form .input-group {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    background-color: #fff;
    border-radius: 5px;
    padding: 1px;
}

.footer-section .subscribe .subscribe-box .subscribe-form .input-group .form-control {
    float: left;
    width: calc(100% - 100px);
    background-color: transparent;
    border: none;
    height: 40px;
    padding: 0 15px;
    outline: none !important;
    box-shadow: none !important;
    font-size: 16px;
}

.footer-section .subscribe .subscribe-box .subscribe-form .input-group .btn {
    float: right;
    width: 100px;
    height: 40px;
    padding: 7px 15px !important;
    background: linear-gradient(45deg, #0c3762, #2563A1);
    border: none;
    border-radius: 6px;
    color: #fff !important;
    font-size: 14px;
    line-height: 20px;
}

footer {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    background: #061522;
    padding: 120px 0 40px 0;
}

footer .footer-logo {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

footer .footer-logo img {
    display: block;
    width: auto;
    max-width: 100%;
}

footer ul {
    float: left;
    width: 100%;
    margin: 0;
    padding: 0;
}

footer ul li {
    float: left;
    width: 100%;
    margin: 0 0 3px 0;
    font-size: 12px;
    line-height: 24px;
    color: #fff;
    font-weight: 400;
    position: relative;
}

footer ul li:last-child {
    margin-bottom: 0;
}

footer ul li a {
    color: #fff;
    font-size: 12px;
    line-height: 18px;
}

footer ul li a:hover {
    color: #eba40a;
}

footer ul.footer-address li {
    padding-left: 35px;
    margin-bottom: 15px;
    font-size: 14px;
}

footer ul.footer-address li i {
    position: absolute;
    left: 0;
    top: 1px;
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 24px;
    text-align: left;
}

footer ul.footer-address li i.fa-envelope {
    font-size: 18px;
}

footer ul.footer-address li a {
    font-size: 14px;
}

footer .footer-social {
    float: left;
    width: 100%;
    margin: 10px 0 0 0;
}

footer .footer-social a {
    float: left;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    padding: 5px;
    margin: 0 12px 0 0;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #223b84;
}

footer .footer-social a:last-child {
    margin-right: 0;
}

footer .footer-social a:hover {
    background-color: #ccc;
}

footer h5 {
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    font-weight: 600;
}

footer ul.offices li {
    float: left;
    width: 20%;
}

.footer-copyright {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    background-color: #343a40;
    padding: 10px 0;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    font-weight: 300;
}

/*footer css end here*/

.top-links-section {
    position: relative;
    z-index: 2;
    margin-top: -70px;
    width: calc(100% - 30px);
    margin-left: 15px;
    background-color: #e7f4fe;
    border-radius: 15px;
    background-image: url("/public/images/body-bg7.png");
    background-repeat: no-repeat;
    background-size: 110px auto;
    background-position: left bottom;
}

.top-links-section ul {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    text-align: center;
    padding: 10px 0;
}

.top-links-section ul li {
    display: inline-block;
    width: auto;
    margin: 20px 15px 10px;
    vertical-align: top;
}

.top-links-section ul li a {
    display: inline-block;
    width: auto;
    background-color: #fee09f;
    border-radius: 30px;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    font-weight: 500;
    padding: 10px 40px;
}

.top-links-section ul li a:hover,
.top-links-section ul li.active a {
    background-color: #e6a311;
}

.abroad-section9 {
    padding: 80px 0;
    position: relative;
}

.abroad-section9:after {
    position: absolute;
    left: -50px;
    top: 100px;
    width: 300px;
    height: 400px;
    content: "";
    background-image: url("/public/images/body-bg3.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: left top;
}

.abroad-section9:before {
    position: absolute;
    right: 0;
    top: 0;
    width: 260px;
    height: 570px;
    content: "";
    background-image: url("/public/images/body-bg2.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: right top;
}

.abroad-section9 .top-university-block {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.abroad-section9 .top-university-block .university-item {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding-left: 90px;
    padding-top: 140px;
}

.abroad-section9 .top-university-block .university-box {
    float: right;
    width: 100%;
    max-width: 420px;
    vertical-align: top;
    border: 1px solid #fee09f;
    padding: 30px 15px 50px;
    padding-left: 140px;
    position: relative;
    background-color: #f5f8fa;
}

.abroad-section9 .top-university-block .university-box .image {
    position: absolute;
    left: -80px;
    bottom: 0;
    width: 200px;
    height: 318px;
}

.abroad-section9 .top-university-block .university-box .icon {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin: 0 0 15px 0;
}

.abroad-section9 .top-university-block .university-box .icon img {
    display: block;
    width: auto;
    max-width: 50px;
}

.abroad-section9 .top-university-block .university-box h3 {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin: 0;
    padding: 0;
    font-size: 24px;
    line-height: 34px;
    color: #000;
    font-weight: 500;
    text-align: right;
}

.abroad-section9 .top-university-block .university-box .read-more {
    position: absolute;
    right: 5px;
    bottom: 6px;
    width: auto;
    z-index: 1;
}

.abroad-section9 .top-university-block .university-box .read-more a {
    display: inline-block;
    width: auto;
    vertical-align: top;
    text-align: center;
    color: #eba40a;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
}

.abroad-section9 .top-university-block .university-box .read-more a:hover {
    color: #000;
}

.abroad-section9 .top-university-block .university-box .read-more a:after {
    display: inline-block;
    vertical-align: top;
    width: 30px;
    height: 20px;
    content: "";
    background-image: url("/public/images/black-arrow-icon.png");
    background-repeat: no-repeat;
    background-size: 16px auto;
    background-position: left center;
    margin: 0 0 0 8px;
    transition: all 0.3s ease-in-out;
}

.abroad-section9 .top-university-block .university-box .read-more a:hover:after {
    background-position: 5px center;
}

.navbar.navbar-expand-lg {
    padding: 0;
}

.navbar .collapse {
    visibility: visible;
}