body {
  font-family: 'Poppins', sans-serif !important;
  position: relative;

}

/* .container {
  margin: 0 auto;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
} */


.form-layout {
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  background: #fff;
}

@media only screen and (max-width: 560px) {
  .form-layout {
    padding: 0;
  }
}

.login-container {
  padding: 40px 20px;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  background: #fff;
  border-radius: 20px;
  width: 930px;
}

.login-btn {
  border-radius: 25px;
  display: flex;
  padding: 5px 10px 10px;
  justify-content: center;
  width: 100%;
  height: auto;
}


a {
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.display-inline {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.abroad-heading {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  text-align: center;
}

.abroad-heading h2 {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin: 0 0 15px 0;
  padding: 0;
  font-size: 36px;
  line-height: 44px;
  color: #225e98;
  font-weight: 700;
}

.abroad-heading h2 span {
  font-weight: 700 !important;
  color: #eba40a;
}

.abroad-heading p {
  display: inline-block;
  width: 100%;
  max-width: 750px;
  vertical-align: top;
  margin: 5px 0 15px 0;
  padding: 0;
  font-size: 18px;
  line-height: 30px;
  color: #000 !important;
  font-weight: 400 !important;
}

.abroad-top-section {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  position: relative;
  background-color: #032343;
}

.abroad-top-section:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 420px;
  content: "";
  /* background-image: url("../images/study-abroad/header-left-image.png"); */
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top left;
}

.abroad-top-section .banner-top-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
}

.abroad-top-section .banner-top-image img {
  float: right;
  width: 100%;
  max-width: 500px;
}

.abroad-top-section .banner-top-image:after {
  position: absolute;
  left: 87%;
  top: 10%;
  width: 170px;
  height: 260px;
  content: "";
  /* background-image: url("../images/study-abroad/header-top-icon.png"); */
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top center;
  z-index: 1;
}

.abroad-top-section .banner-top-content {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 70px 0 80px 30px;
}

.abroad-top-section .banner-top-content h1 {
  font-size: 48px;
  line-height: 64px;
  color: #fff;
  font-weight: 400;
}

.abroad-top-section .banner-top-content h1 span {
  font-weight: 400 !important;
  color: #fee09f;
}

.abroad-top-section .banner-top-content h2 {
  font-size: 24px;
  line-height: 34px;
  color: #f0aa0c;
  font-weight: 400;
  margin: 20px 0 0 0;
}

.abroad-top-section .banner-top-content p {
  font-size: 16px;
  line-height: 30px;
  color: #ffffff !important;
  margin: 20px 0 0 0;
}

.abroad-top-section .banner-top-content .top-btn {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin: 35px 0 0 0;
  padding-left: 50px;
}

.abroad-top-section .banner-top-content .top-btn a {
  display: inline-block;
  width: auto;
  vertical-align: top;
  padding: 15px 60px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500 !important;
  color: #fff !important;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 40px;
  border: 2px solid #4889c9;
  background-image: linear-gradient(to right, #0d3863, #2462a0);
  position: relative;
  overflow: hidden;
}

.abroad-top-section .banner-top-content .top-btn a:after {
  background: #fff;
  content: "";
  height: 100%;
  left: -100%;
  opacity: 0.09;
  position: absolute;
  top: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  z-index: 1;
}

.abroad-top-section .banner-top-content .top-btn a:hover:after {
  left: 0;
}

.abroad-section1 {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  position: relative;
  z-index: 3;
  margin-top: -90px;
}

.abroad-section1 .abroad-top-links {
  display: flex;
  width: 100%;
  background-color: #ffd066;
}

.abroad-section1 .abroad-top-links .links-item {
  width: 14.285%;
  text-align: center;
  border-right: 1px solid #000;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.abroad-section1 .abroad-top-links .links-item:hover {
  background-color: #eba40a;
}

.abroad-section1 .abroad-top-links .links-item.border-none {
  border-right: none;
}

.abroad-section1 .abroad-top-links .links-item .link-icon {
  display: inline-block;
  width: 100%;
  padding: 10px;
  text-align: center;
}

.abroad-section1 .abroad-top-links .links-item .link-icon img {
  display: inline-block;
  width: auto;
  max-width: 80px;
}

.abroad-section1 .abroad-top-links .links-item p {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-size: 15px;
  line-height: 20px;
  color: #000 !important;
  font-weight: 600 !important;
}

.abroad-section1 .abroad-top-links .links-item a.full-link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.abroad-section2 {
  padding: 60px 0 20px 0;
  position: relative;
}

.abroad-section2:before {
  position: absolute;
  left: 0;
  top: -30px;
  width: 440px;
  height: 525px;
  content: "";
  /* background-image: url("../images/study-abroad/body-bg1.png"); */
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left top;
}

.abroad-section2:after {
  position: absolute;
  right: 0;
  top: -48px;
  width: 312px;
  height: 676px;
  content: "";
  /* background-image: url("../images/study-abroad/body-bg2.png"); */
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: right top;
}

.abroad-section2 .container {
  position: relative;
  z-index: 2;
}

.abroad-section2 .countries-section {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin-top: 30px;
}

.abroad-section2 .countries-section .countries-block {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 5px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
}

.abroad-section2 .countries-section .countries-block .country-img {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.abroad-section2 .countries-section .countries-block .country-img img {
  display: block;
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.abroad-section2 .countries-section .countries-block .country-icon {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  text-align: center;
  margin-top: -34px;
  position: relative;
  z-index: 1;
}

.abroad-section2 .countries-section .countries-block .country-icon span {
  display: inline-block;
  vertical-align: top;
  width: 68px;
  height: 68px;
  background-color: #fff;
  border-radius: 50%;
  padding: 4px;
  box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.1);
}

.abroad-section2 .countries-section .countries-block .country-icon span img {
  display: block;
  width: 100%;
  border-radius: 50%;
}

.abroad-section2 .countries-section .countries-block h2 {
  display: inline-block;
  width: 100%;
  padding: 0 5px;
  margin: 15px 0 15px 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
  text-align: center;
}

.abroad-section2 .countries-section .countries-block .more-btn {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.abroad-section2 .countries-section .countries-block .more-btn a {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  background-color: #ffd066;
  text-align: center;
  color: #000 !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600 !important;
  padding: 10px 5px;
  border-radius: 0 0 5px 5px;
}

.abroad-section2 .countries-section .countries-block .more-btn a:after {
  display: inline-block;
  vertical-align: top;
  width: 30px;
  height: 20px;
  content: "";
  /* background-image: url("../images/study-abroad/arrow-icon.png"); */
  background-repeat: no-repeat;
  background-size: 16px auto;
  background-position: left center;
  margin: 0 0 0 8px;
  transition: all 0.3s ease-in-out;
}

.abroad-section2 .countries-section .countries-block .more-btn a:hover:after {
  background-position: 5px center;
}

.abroad-section3 {
  padding-top: 70px;
}

.preparation-section {
  margin-top: 10px;
  margin-bottom: 60px;
  padding-top: 25px;
  /* background-image: url("../images/study-abroad/test-preparations-bg.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.preparation-section .preparation-box {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  position: relative;
  transition: all 0.5s ease-in-out;
  border: 1px solid #d4d9e6;
  border-radius: 5px;
  overflow: hidden;
}

.preparation-section .preparation-box:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #000;
  opacity: 0.5;
  content: "";
  transition: all 0.5s ease-in-out;
}

.preparation-section .preparation-box:hover:after {
  opacity: 0;
}

.preparation-section .preparation-box:before {
  position: absolute;
  right: 20px;
  top: 10%;
  width: 34px;
  height: 34px;
  z-index: 2;
  background-color: #eba40a;
  border-radius: 50%;
  content: "\f061";
  font-family: fontawesome;
  font-size: 14px;
  line-height: 34px;
  color: #fff;
  text-align: center;
  transition: all 0.5s ease-in-out;
}

.preparation-section .preparation-box .preparation-img {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.preparation-section .preparation-box .preparation-img img {
  display: block;
  width: 100%;
}

.preparation-section .preparation-box h2 {
  position: absolute;
  left: 0;
  bottom: 15px;
  padding: 0 30px 10px;
  font-size: 28px;
  line-height: 38px;
  color: #fff;
  font-weight: 700;
  z-index: 2;
  transition: all 0.5s ease-in-out;
}

.preparation-section .preparation-box:hover h2,
.preparation-section .preparation-box:hover:before {
  opacity: 0;
  visibility: hidden;
}

.preparation-section .preparation-box .preparation-cnt {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
  background-color: rgba(254, 254, 254, 0.85);
  padding: 20px 15px 50px 15px;
  border-radius: 0 0 2px 2px;
  transition: all 0.5s ease-in-out;
  transform: translateY(100%);
}

.preparation-section .preparation-box:hover .preparation-cnt {
  transform: translateY(0);
}

.preparation-section .preparation-box .preparation-cnt:after {
  position: absolute;
  right: 20px;
  top: 10%;
  width: 34px;
  height: 34px;
  z-index: 2;
  background-color: #000;
  border-radius: 50%;
  content: "\f061";
  font-family: fontawesome;
  font-size: 14px;
  line-height: 34px;
  color: #fff;
  text-align: center;
}

.preparation-section .preparation-box .preparation-cnt h3 {
  display: inline-block;
  width: 100%;
  padding: 0 45px 0 0;
  vertical-align: top;
  margin: 0 0 20px 0;
}

.preparation-section .preparation-box .preparation-cnt h3 a {
  font-size: 24px;
  line-height: 30px;
  color: #000 !important;
  font-weight: 700 !important;
}

.preparation-section .preparation-box .preparation-cnt h3 a:hover {
  color: #eba40a !important;
}

.preparation-section .preparation-box .preparation-cnt p {
  display: inline-block;
  width: 100%;
  padding: 0;
  vertical-align: top;
  font-size: 16px;
  line-height: 24px;
  color: #000 !important;
  font-weight: 400 !important;
  margin: 0;
}

.preparation-block {
  position: relative;
}

.preparation-block .owl-dots {
  position: absolute;
  left: 0;
  bottom: -60px;
  height: 20px;
  width: 100%;
  margin: 0 !important;
  /* background-image: url("../images/study-abroad/owl-dot-bg.png"); */
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.preparation-block .owl-dots .owl-dot {
  margin: 0 4px;
}

.preparation-block .owl-dots .owl-dot span {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
}