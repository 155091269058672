@media screen and (max-width:1800px) {
	.abroad-top-section:before {
		opacity: 0.2;
	}
}

@media screen and (max-width:1400px) {
	.abroad-top-section .banner-top-image:after {
		width: 100px;
		height: 155px;
		left: 80%;
		top: 20%;
	}

	.abroad-section2:before {
		width: 240px;
		height: 400px;
		top: 0;
	}

	.abroad-section2:after {
		width: 180px;
		height: 450px;
		top: 0;
	}

	.abroad-section4:after {
		width: 200px;
		height: 280px;
	}

	.abroad-section4:before {
		width: 400px;
		height: 400px;
	}

	.abroad-section4 {
		padding-bottom: 30px;
	}

	.abroad-section6 .video-block {
		margin-top: 50px;
	}

	.abroad-section7:before {
		width: 180px;
		height: 450px;
		top: 0;
	}

	.abroad-section7:after {
		width: 200px;
		height: 280px;
	}

	.inner-faq-section .right-block {
		padding: 20px;
	}

	.inner-faq .accordion {
		padding-bottom: 0;
	}

	.inner-faq .accordion .accordion-item .accordion-body p {
		padding-bottom: 0;
		font-size: 14px;
		line-height: 20px;
	}

	.inner-faq .accordion .accordion-item {
		margin-bottom: 15px;
	}
}

@media screen and (max-width:1199px) {
	.abroad-top-section .banner-top-content {
		padding: 30px 0 120px 10px;
	}

	.abroad-top-section .banner-top-content h1 {
		font-size: 40px;
		line-height: 58px;
	}

	.abroad-top-section .banner-top-content h2 {
		font-size: 18px;
		line-height: 24px;
	}

	.abroad-top-section .banner-top-content p {
		font-size: 14px;
		line-height: 24px;
	}

	.abroad-top-section .banner-top-image img {
		max-width: 420px;
	}

	.abroad-top-section .banner-top-image:after {
		width: 70px;
		height: 105px;
	}

	.abroad-section1 .abroad-top-links .links-item .link-icon img {
		max-width: 60px;
	}

	.abroad-section1 .abroad-top-links .links-item p {
		font-size: 13px;
	}

	.preparation-section .preparation-box h2 {
		font-size: 20px;
		line-height: 30px;
		padding: 0 15px;
	}

	.preparation-section .preparation-box:before {
		top: 10px;
		right: 10px;
	}

	.preparation-section .preparation-box .preparation-cnt {
		padding: 10px 15px 25px 15px;
	}

	.preparation-section .preparation-box .preparation-cnt h3 a {
		font-size: 18px;
		list-style: 24px;
	}

	.preparation-section .preparation-box .preparation-cnt p {
		font-size: 14px;
		list-style: 20px;
	}

	.preparation-section .preparation-box .preparation-cnt:after {
		resize: 15px;
		top: 12px;
	}

	.abroad-section4 {
		padding: 50px 0 20px;
		overflow: hidden;
	}

	.abroad-section4 .visa-block {
		margin-top: 10px;
	}

	.abroad-section5 .documentations-block .documentations-box .icon {
		width: 120px;
		height: 120px;
		padding: 33px 38px;
	}

	.abroad-section5 .documentations-block .documentations-box .icon img {
		max-width: 50px;
	}

	.abroad-section5 .documentations-block .documentations-box .cnt {
		border-radius: 15px;
		padding: 15px 15px;
		margin-top: 30px;
	}

	.abroad-section5 .documentations-block .documentations-box .cnt h3 {
		font-size: 16px;
	}

	.abroad-section5 .documentations-block .documentations-box .cnt p {
		font-size: 13px;
		line-height: 20px;
	}

	.abroad-section5 .documentations-block .documentations-box .icon .number {
		width: 36px;
		height: 36px;
		font-size: 18px;
		line-height: 36px;
		bottom: -15px;
	}

	.abroad-section6 {
		padding-top: 30px;
	}

	.abroad-section6 .video-block .video-box .video-title {
		font-size: 16px;
		line-height: 20px;
		min-height: 60px;
	}

	.abroad-section7 {
		padding-top: 70px;
		overflow: hidden;
	}

	.abroad-faq-section .faq-right-block {
		position: relative;
		z-index: 2;
	}

	.abroad-faq-section .faq-right-block .right-top-block {
		font-size: 20px;
		line-height: 28px;
		padding: 30px 50px 30px 20px;
		background-size: 120px;
	}

	.abroad-faq-section .faq-right-block .right-image p {
		font-size: 16px;
	}

	.abroad-section8 {
		margin-top: 50px;
		padding: 40px 0 60px 0;
	}

	.abroad-blog-section {
		margin-top: 30px;
	}

	aside .form-outer {
		padding: 15px;
		border-radius: 15px;
	}

	.footer-section .subscribe .subscribe-box {
		padding: 20px;
		border-radius: 15px;
	}

	.footer-section .subscribe .subscribe-box h4 {
		font-size: 24px;
	}

}

@media screen and (max-width:991px) {
	.desktop-hide-section {
		display: block;
	}

	.mobile-hide-section {
		display: none;
	}

	.abroad-heading h2 {
		font-size: 28px;
		line-height: 36px;
	}

	.abroad-heading p {
		font-size: 16px;
		line-height: 24px;
		margin-top: 0;
	}

	.abroad-top-section .banner-top-content h1 {
		font-size: 30px;
		line-height: 44px;
	}

	.abroad-section1 .abroad-top-links {
		flex-wrap: wrap;
		background-color: transparent;
		justify-content: center;
	}

	.abroad-section1 .abroad-top-links .links-item {
		width: 25%;
		margin-top: 1px;
		background-color: #ffd066;
		border-right-color: #fff;
	}

	.abroad-section1 .abroad-top-links .links-item:nth-child(4) {
		border-right: none;
	}

	.abroad-section2 .countries-section {
		margin-top: 20px;
	}

	.abroad-section2 .countries-section .countries-block h2 {
		font-size: 14px;
		line-height: 20px;
	}

	.abroad-section2 .countries-section .countries-block .more-btn a {
		font-size: 12px;
	}

	.abroad-section3 {
		padding-top: 40px;
	}

	.abroad-section4 .visa-block .visa-box .visa-cont .icon {
		margin-top: 10px;
	}

	.abroad-section4 .visa-block .visa-box .visa-cont h2 {
		font-size: 18px;
	}

	.abroad-section4 .visa-block .visa-box .visa-cont .more-btn {
		margin-top: 15px;
	}

	.abroad-section4 .visa-block .visa-box .visa-cont .more-btn a {
		font-size: 14px;
	}

	.abroad-faq-section .abroad-faq-block:before {
		display: none;
	}

	.abroad-faq-section .abroad-faq-block {
		padding-top: 0;
	}

	.abroad-faq-section {
		margin-top: 30px;
	}

	.abroad-faq-section .abroad-faq-block .accordion .accordion-item .accordion-header {
		font-size: 14px;
		line-height: 20px;
	}

	.abroad-faq-section .abroad-faq-block .accordion .accordion-item .accordion-body {
		padding-top: 10px;
	}

	.abroad-faq-section .abroad-faq-block .accordion .accordion-item .accordion-body p {
		font-size: 12px;
		line-height: 20px;
	}

	.abroad-blog-section .abroad-blogs .blog-cnt {
		padding: 35px 15px 20px 15px;
	}

	.abroad-blog-section .abroad-blogs .blog-cnt h3 {
		font-size: 16px;
		line-height: 20px;
	}

	.abroad-blog-section .abroad-blogs .blog-cnt p {
		font-size: 13px;
		line-height: 20px;
		margin-top: 15px;
	}

	.abroad-blog-section .abroad-blogs .blog-cnt .blog-btn {
		margin-top: 20px;
	}

	.abroad-blog-section .abroad-blogs .blog-cnt .blog-btn a {
		font-size: 12px;
	}

	header .logo {
		max-width: 140px;
		margin: 10px 0;
	}

	header .header-ul li,
	header .header-ul li a {
		display: inline-block;
		vertical-align: top;
	}

	header .header-ul li a .fa {
		font-size: 30px;
	}

	header .header-ul li a .fa-whatsapp {
		margin-top: -4px;
		display: inline-block;
		vertical-align: top;
	}

	header .header-ul li+li {
		padding-left: 10px;
	}

	header .header-ul {
		margin: 0;
		margin-top: 24px;

	}

	header .navbar-light .navbar-toggler {
		padding: 0;
		border: none;
	}

	header .navbar-toggler {
		display: inline-block;
		padding: 0;
		outline: none;
		vertical-align: top;
		margin-top: -5px;
	}

	header .navbar-toggler .navbar-toggler-icon {
		background-image: url("/public/images/nav-toggle.svg");
	}

	header .navbar-nav {
		position: relative;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 11;
		padding: 10px 15px;
		max-height: 300px;
		overflow-y: auto;
	}

	.inner-top-section {
		padding-top: 40px;
	}

	.inner-top-section .banner-top-content h1 {
		font-size: 36px;
		line-height: 48px;
	}

	.inner-content-section .form-outer h2 {
		margin-bottom: 30px;
		font-size: 24px;
		line-height: 30px;
	}

	.enquiry-form .form-group .form-control {
		height: 40px !important;
		border-radius: 5px;
		font-size: 12px;
		padding: 0 10px;
	}

	.enquiry-form .row {
		margin-left: -5px;
		margin-right: -5px;
	}

	.enquiry-form .row .col-md-6 {
		padding-left: 5px;
		padding-right: 5px;
	}

	.enquiry-form .form-group .custom-control-label {
		font-size: 12px;
	}

	.inner-content-section h2 {
		font-size: 22px;
		line-height: 30px;
		margin-bottom: 5px;
	}

	.inner-content-section p {
		font-size: 14px;
		line-height: 24px;
		padding-bottom: 10px;
	}

	.content-section1 .content-block .accordion .accordion-item .accordion-body p {
		font-size: 13px;
		line-height: 20px;
	}

	.inner-content-section ul,
	.inner-content-section ol {
		padding-left: 10px;
	}

	.inner-content-section ul li,
	.inner-content-section ol li {
		font-size: 14px;
		line-height: 20px;
		padding-left: 22px;
	}

	.inner-content-section aside .category-list-outer ul li:before {
		width: 12px;
		height: 12px;
	}

	.blog-list-outer .blogs .blog-img {
		width: 90px;
	}

	.blog-list-outer .blogs .blog-cnt {
		width: calc(100% - 90px);
		padding-left: 10px;
	}

	.blog-list-outer .blogs .blog-cnt h4 {
		font-size: 14px;
		line-height: 20px;
	}

	.blog-list-outer .blogs .blog-cnt .blog-date {
		font-size: 12px;
		line-height: 20px;
	}

	.blog-list-outer .blogs .blog-cnt .blog-date i {
		font-size: 14px;
		margin-right: 2px;
	}

	.inner-faq-section {
		margin-top: 30px;
	}

	.inner-faq-section .left-block {
		display: none;
	}

	.inner-faq-section .right-block {
		width: 100%;
		padding: 15px;
	}

	.inner-content-section .content-section1:after {
		width: 100%;
	}

	.related-post-section {
		margin-top: 30px;
	}

	.related-post-section .post-block {
		margin: 20px 0;
	}

	.related-post-section .post-block .post-item .post-img img {
		height: 220px;
	}

	.inner-content-section .add-section {
		margin-top: 20px;
	}

	.inner-content-section .content-section1 {
		padding-top: 20px;
	}

	.footer-section .subscribe .subscribe-box h4 {
		font-size: 18px;
		line-height: 24px;
	}

	footer h5 {
		margin-top: 20px;
	}

	footer .col-12.mt-4 {
		margin-top: 0 !important;
	}

}

@media screen and (max-width:767px) {
	.abroad-top-section .banner-top-content {
		padding: 20px 0 90px 0;
		position: relative;
		z-index: 2;
	}

	.abroad-top-section .banner-top-content .top-btn {
		padding-left: 0;
	}

	.abroad-top-section .banner-top-image:after {
		display: none;
	}

	.abroad-top-section .banner-top-image {
		position: absolute;
		right: 10px;
		bottom: 40px;
		opacity: 0.4;
	}

	.abroad-top-section .banner-top-image img {
		max-width: 250px;
	}

	.abroad-section1 {
		margin-top: -60px;
	}

	.abroad-section2 {
		padding: 40px 0 0 0;
	}

	.abroad-section2 .countries-section {
		margin-top: 0;
	}

	.abroad-section2 .countries-section .countries-block {
		margin-top: 20px;
	}

	.preparation-block .owl-dots {
		bottom: -40px;
	}

	.preparation-block .owl-dots .owl-dot span {
		width: 12px;
		height: 12px;
	}

	.abroad-section4 .visa-block .visa-box .image img {
		max-height: 220px;
	}

	.abroad-section5 .documentations-block {
		margin-top: 0;
	}

	.abroad-section5 .documentations-block .documentations-box {
		margin-top: 40px;
	}

	.abroad-top-section:before {
		width: 200px;
	}

	.abroad-blog-section {
		margin-top: 0;
	}

	.abroad-blog-section .abroad-blogs {
		margin-top: 30px;
	}

	.header-top .header-ul li a.fee-deposition span {
		display: none;
	}

	.inner-top-section {
		padding: 30px 0;
	}

	.inner-top-section .banner-top-image {
		display: none;
	}

	.inner-top-section .banner-top-content {
		max-width: 100%;
		padding-top: 0;
	}

	.inner-top-section .banner-top-content h1 {
		display: inline-block;
		width: 100%;
		max-width: 400px;
		margin: 0;
		font-size: 30px;
		line-height: 40px;
	}

	.inner-content-section aside {
		margin-top: 20px;
	}

	.enquiry-form .form-group {
		margin-bottom: 15px;
	}

	.enquiry-form .form-group textarea.form-control {
		height: 80px !important;
	}

	.related-post-section .post-block .owl-dots .owl-dot {
		width: 12px;
		height: 12px;
		border-width: 2px;
		margin: 0 2px;
	}

	.footer-section {
		margin-top: 20px;
	}

	footer {
		padding-bottom: 20px;
	}

	footer ul.footer-address li {
		font-size: 12px;
	}

	footer ul.footer-address li a {
		font-size: 12px;
	}

	footer .footer-social {
		margin-bottom: 30px;
	}

	footer .footer-links {
		display: inline-block;
		width: 100%;
		vertical-align: top;
		margin-bottom: 1px;
		background-color: #223b84;
	}

	footer .footer-links ul {
		display: none;
		width: 100%;
		vertical-align: top;
		padding: 10px 15px 10px;
		border-top: 1px solid #3a5299;
	}

	footer .footer-links h5 {
		display: inline-block;
		width: 100%;
		font-size: 14px;
		line-height: 20px;
		padding: 10px 15px;
		padding-right: 40px;
		margin: 0;
		font-weight: 400;
		position: relative;
	}

	footer .footer-links h5:after {
		position: absolute;
		right: 10px;
		width: 20px;
		height: 20px;
		top: 10px;
		content: "\f13a";
		font-family: fontawesome;
		font-size: 18px;
		line-height: 20px;
		color: #fff;
		text-align: center;
		transition: all 0.5s ease-in-out;
	}

	footer .footer-links.show h5:after {
		content: "\f139";
	}

	.footer-copyright {
		font-size: 12px;
	}

	.content-section2 {
		margin-top: 20px;
	}

	.inner-faq .accordion .accordion-item .accordion-header {
		font-size: 14px;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.inner-faq .accordion .accordion-item .accordion-header:after {
		top: 10px;
	}

	.inner-faq .accordion .accordion-item .accordion-body {
		padding: 10px 15px;
	}

	.inner-faq .accordion .accordion-item .accordion-body p {
		font-size: 12px;
		line-height: 20px;
	}
}

@media screen and (max-width:575px) {
	.abroad-section1 .abroad-top-links .links-item {
		width: 50%;
	}

	.abroad-section1 .abroad-top-links .links-item:nth-child(even) {
		border-right: none;
	}

	.abroad-section1 .abroad-top-links .links-item .link-icon {
		padding: 5px;
	}

	.abroad-section1 .abroad-top-links .links-item p {
		margin-bottom: 10px;
	}

	.abroad-section1 .abroad-top-links .links-item .link-icon img {
		max-width: 40px;
	}

	footer ul.offices li {
		width: 33.33%;
	}
}

@media screen and (max-width:359px) {
	header .header-ul li a .fa {
		font-size: 26px;
	}
}